<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="详情"
      @ok="handleSubmit"
    >
      <a-row>
        <a-descriptions :column="1" class="custom-descriptions-max-9 custom-descriptions">
          <a-descriptions-item label="申请时间">
            {{ data.apply_time }}
          </a-descriptions-item>
          <a-descriptions-item label="申请人">
            {{ data.apply_user_name }}
          </a-descriptions-item>
          <a-descriptions-item label="申请人联系电话">
            {{ data.apply_user_phone_number }}
          </a-descriptions-item>
          <a-descriptions-item label="信息维护类型">
            {{ data.info_type }}
          </a-descriptions-item>
          <a-descriptions-item label="申请状态">
            {{ data.status }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-9"
          v-if="['valid_package', 'invalid_package', 'valid_product', 'invalid_product','delete_package','delete_product'].includes(this.data.info_type_slug)"
        >
          <a-descriptions-item label="申请内容">
            {{ data.apply_content }}
          </a-descriptions-item>
          <a-descriptions-item label="变更原因">
            {{ data.reason }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions :column="1" class="custom-descriptions-max-9 custom-descriptions" v-else-if="data.info_type_slug === 'modify_head_user'">
          <a-descriptions-item label="订单编号">
            {{ data.order_no }}
          </a-descriptions-item>
          <a-descriptions-item label="原负责人">
            {{ data.before_name }}
          </a-descriptions-item>
          <a-descriptions-item label="原负责人联系电话">
            {{ data.before_phone_number }}
          </a-descriptions-item>
          <a-descriptions-item label="新负责人">
            {{ data.name }}
          </a-descriptions-item>
          <a-descriptions-item label="新负责人联系电话">
            {{ data.phone_number }}
          </a-descriptions-item>
          <a-descriptions-item label="修改原因">
            {{ data.reason }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions :column="1" class="custom-descriptions-max-9 custom-descriptions" v-else-if="data.info_type_slug === 'modify_master'">
          <a-descriptions-item label="订单编号">
            {{ data.order_no }}
          </a-descriptions-item>
          <a-descriptions-item label="原丧属">
            {{ data.before_name }}
          </a-descriptions-item>
          <a-descriptions-item label="原丧属联系电话">
            {{ data.before_phone_number }}
          </a-descriptions-item>
          <a-descriptions-item label="新丧属">
            {{ data.name }}
          </a-descriptions-item>
          <a-descriptions-item label="新丧属联系电话">
            {{ data.phone_number }}
          </a-descriptions-item>
          <a-descriptions-item label="修改原因">
            {{ data.reason }}
          </a-descriptions-item>
        </a-descriptions>
      </a-row>
      <div v-if="['checking', 'uncheck'].includes(data.apply_status_slug) && data.is_can_user_check">
        <a-divider />
        <a-form
          class="custom-compact-form"
          :form="form"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 15 }"
          @submit="handleSubmit"
        >
          <a-form-item label="审核结果">
            <a-radio-group
              v-decorator="['result', {
                rules: [{ required:true,type: 'string',message: '请选择审核结果' }]
              }]"
            >
              <a-radio :value="'passed'">通过</a-radio>
              <a-radio :value="'no_passed'">未通过</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="备注">
            <a-textarea
              v-decorator="['remark', {
                normalize: this.$lodash.trim,
                rules: [
                  { max: 300, message: '最多300个字符' },
                ]
              }]"
            />
          </a-form-item>
        </a-form>
      </div>
      <template slot="footer" v-else>
        <a-button type="primary" @click="handleCancel">关闭</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { checkInfoModifyApply, findInfoModifyApplyDetail } from '@/api/info_modify_apply'

export default {
  name: 'DetailInfoModifyApply',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'info_modify_apply_detail' }),
      submitting: false,
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findInfoModifyApplyDetail(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    },

    handleCancel() {
      this.isShow = false
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          checkInfoModifyApply(this.id, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
